import React from 'react';

const CookiePreferencesModal = ({ onClose, onSave }) => {
    const [preferences, setPreferences] = React.useState({
        necessary: true,
        analytics: false,
        marketing: false,
    });

    const handleCheckboxChange = (event) => {
        setPreferences({
            ...preferences,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSave = () => {
        // Save preferences to localStorage or cookie
        localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
        onSave(preferences);
        onClose();
    };

    return (
        <div style={modalStyles}>
            <h3 style={{ marginBottom: '15px' }}>Manage Cookie Preferences</h3>
            <div style={checkboxContainerStyle}>
                <label style={labelStyle}>
                    <input
                        type="checkbox"
                        name="necessary"
                        checked={preferences.necessary}
                        disabled
                        style={checkboxStyle}
                    />
                    Necessary Cookies (Required)
                </label>
                <label style={labelStyle}>
                    <input
                        type="checkbox"
                        name="analytics"
                        checked={preferences.analytics}
                        onChange={handleCheckboxChange}
                        style={checkboxStyle}
                    />
                    Analytics Cookies
                </label>
                <label style={labelStyle}>
                    <input
                        type="checkbox"
                        name="marketing"
                        checked={preferences.marketing}
                        onChange={handleCheckboxChange}
                        style={checkboxStyle}
                    />
                    Marketing Cookies
                </label>
            </div>
            <div style={buttonContainerStyle}>
                <button style={buttonStyle} onClick={handleSave}>Save Preferences</button>
                <button style={buttonStyle} onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const modalStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#00adef', // Same background as consent box
    padding: '20px',
    borderRadius: '5px', // Rounded corners for aesthetics
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    zIndex: 1001,
    color: '#fff', // Text color for better contrast
};

const checkboxContainerStyle = {
    marginBottom: '20px', // Space between checkboxes and buttons
};

const labelStyle = {
    display: 'block',
    marginBottom: '10px', // Space between checkboxes
};

const checkboxStyle = {
    marginRight: '10px', // Space between checkbox and label text
};

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
};

const buttonStyle = {
    backgroundColor: '#fff', // Button background color
    color: '#00adef', // Button text color to match the modal
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease', // Transition for hover effect
};

export default CookiePreferencesModal;
