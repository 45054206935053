import React, { useEffect, useState, useMemo } from 'react';
import Layout from '../components/Layout/Layout';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, IconButton } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { gethomeandtechdata } from '../redux/features/homeandtechSlice.js';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import Spinner from '../components/shared/spinner.js';
import { formatTimeAgo } from '../utils/timeUtils.js';
// import ImageFiller from 'react-image-filler';
import { FavoriteSharp, Share, Email, Facebook, WhatsApp, X, LinkedIn } from '@mui/icons-material'; // Import icons
import axios from 'axios';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CopyLinkButton from '../components/Layout/CopyLinkButton.js';
import { useQueryClient, useQuery } from 'react-query';
import { toast } from 'react-toastify';
//import { ClipLoader } from 'react-spinners';

import { useTheme } from '../components/theme-context.js';
import { Helmet } from 'react-helmet';






const Dashboard = () => {

    const { isDarkMode } = useTheme();


    const queryClient = useQueryClient(); // Get the queryClient instance


    const { user } = useSelector((state) => state.auth);
    const userId = user?._id; // Assuming userId is stored in '_id' property

    // const [uniqueArticles, setUniqueArticles] = useState([]); // Stores filtered articles
    const [favoriteStatus, setFavoriteStatus] = useState([]); // Stores article IDs for liked articles
    const [isModalOpen, setIsModalOpen] = useState(false); // stores share icon state
    // const [isClicked, setIsClicked] = useState(false); // Track click state
    // const [isLinkCopied, setIsLinkCopied] = useState(false);

    // const [memoizedHomeandTechData, setMemoizedHomeandTechData] = useState([]);


    //****************************************************************************************************** */

    const dispatch = useDispatch();

    // const data = useSelector((state) => state.homepagedata);
    const { loading } = useSelector((state) => state.alerts);

    //***************************************************************************************************** */

    // ***** CODE COMMENTED ON 04102024 ******************
    // Function to filter and sort articles
    // const filterAndSortArticles = (articles) => {
    //     // console.log('Articles before filtering:', articles);
    //     const filteredArticles = articles.filter((article, index) => {
    //         return articles.findIndex((a) => a.title === article.title) === index;
    //     });
    //     // console.log('Articles after filtering:', filteredArticles);

    //     // Sort filteredArticles by date (assuming 'dateGmt' is the date property)
    //     const sortedArticles = filteredArticles.sort((a, b) => {
    //         return new Date(b.dateGmt) - new Date(a.dateGmt);
    //     });
    //     // console.log('Articles after sorting:', sortedArticles);

    //     return sortedArticles;
    // };





    //************************************************************************************** */

    // Removed filtering of unique articles since filtering is done through API

    // Function to filter and sort articles
    const filterAndSortArticles = (articles) => {
        // console.log('Articles before filtering:', articles);
        // const filteredArticles = articles.filter((article, index) => {
        //     return articles.findIndex((a) => a.title === article.title) === index;
        // });
        // console.log('Articles after filtering:', filteredArticles);

        // Sort filteredArticles by date (assuming 'dateGmt' is the date property)
        const sortedArticles = articles.sort((a, b) => {
            return new Date(b.dateGmt) - new Date(a.dateGmt);
        });
        // console.log('Articles after sorting:', sortedArticles);

        return sortedArticles;
    };


    // useEffect(() => {
    //     if (favoriteIds.length && homeandTechData.payload.length) {
    //         const combinedData = homeandTechData.payload.map((ele) => ({
    //             ...ele,
    //             isFavorite: favoriteIds.includes(ele._id),
    //         }));
    //         setMemoizedHomeandTechData(combinedData);
    //     }
    // }, [favoriteIds, homeandTechData]);


    //***************************************************************************************************** */

    // Filter duplicates and remove logic moved here for clarity
    // useEffect(() => {
    //     if (data && data.articles) {
    //         const filteredArticles = data.articles.filter((article, index) => {
    //             return data.articles.findIndex((a) => a.title === article.title) === index;
    //         });

    //         //sorting code added on 12082024

    //         // Sort filteredArticles by date (assuming 'publishedAt' is the date property)
    //         const sortedArticles = filteredArticles.sort((a, b) => {
    //             return new Date(b.dateGmt) - new Date(a.dateGmt);
    //         });



    //         setUniqueArticles(sortedArticles);
    //     }
    // }, [data]);





    //******************************************************************************************************** */   


    //fetch favorites from api
    const fetchFavorites = async () => {

        dispatch(showLoading()); // Dispatch showLoading action

        try {

            const token = localStorage.getItem('token'); // Retrieve the token from local storage

            if (userId) {
                const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=article`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                        }
                    }
                );
                const favoriteIds = response.data.data;

                // setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

                return response.data.data.map(article => article.articleId);

            }
        } catch (error) {
            dispatch(hideLoading()); // Dispatch hideLoading action

            console.error('Error fetching favorites:', error);
        } finally {
            dispatch(hideLoading()); // Dispatch hideLoading action
        }

    }
    //********************************************************************************************************



    //******************************************************************************************************** */   

    // Fetch data for HomeandTech page (unchanged)


    // useEffect(() => {
    //     const fetchData = async () => {
    //         dispatch(showLoading()); // Dispatch showLoading action

    //         try {
    //             await dispatch(gethomeandtechdata());
    //         } catch (error) {
    //             // Handle error
    //         } finally {
    //             dispatch(hideLoading()); // Dispatch hideLoading action
    //         }
    //     };

    //     fetchData();
    // }, [dispatch]);

    //******************************************************************************************************** */
    //fetch homeand tech data with react query

    // const { data: homeandTechData, error: homeandTecherror, isLoading: isHomeandTechLoading } =

    //     useQuery('homeandTechData', async () => {

    const { data: homeandTechData = { payload: [] }, error: homeandTecherror, isLoading: isHomeandTechLoading }
        = useQuery('homeandTechData', async () => {
            dispatch(showLoading());
            try {
                const result = await dispatch(gethomeandtechdata());
                // console.log('API Response for home and tech data:', result); // Log the entire API response
                return result;
            } catch (error) {
                dispatch(hideLoading());
                toast.error("Error occurred while fetching data from API");
                console.log("Error occurred while loading data on dashboard page", error);
            } finally {
                dispatch(hideLoading());
            }
        }, {
            staleTime: 1800000,
            cacheTime: 900000,
            // select: (result) => {
            //     if (!result || !result.payload) return [];
            //     return result.payload.map((ele) => ({
            //         ...ele,
            //         isFavorite: favoriteIds.includes(ele._id),
            //     }));
            // },
        });


    //****************************************************************************************** */  


    // load favorites with react-query

    // const { data: favoriteIds, error: favoritesError, isLoading: isFavoritesLoading }
    //     = useQuery(['favorites', userId], async () => {

    const { data: favoriteIds = [], error: favoritesError, isLoading: isFavoritesLoading }
        = useQuery(['favorites', userId], async () => {

            dispatch(showLoading());
            try {
                const result = await fetchFavorites(userId);

                // console.log('Favorite IDs:', result); // Add this line

                setFavoriteStatus(result);
                return result;


            } finally {
                dispatch(hideLoading());
            }
        }, {
            enabled: !!userId, // Only run the query if userId is not null
            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes
        });




    //****************************************************************************************** */  


    //************************************************************************************************************** */






    // const memoizedHomeandTechData = useMemo(() => {
    //     if (!favoriteIds.length || !homeandTechData.payload.length) return [];
    //     return homeandTechData.payload.map((ele) => ({
    //         ...ele,
    //         isFavorite: favoriteIds.includes(ele._id),
    //     }));
    // }, [favoriteIds, homeandTechData]);

    const combinedData = useMemo(() => {
        // if (!favoriteIds.length || !homeandTechData.payload.length) return [];
        if (!homeandTechData.payload.length) return [];

        return homeandTechData.payload.map((ele) => ({
            ...ele,
            // isFavorite: favoriteIds.includes(ele._id),
            isFavorite: favoriteIds.length ? favoriteIds.includes(ele._id) : false,

        }));
    }, [favoriteIds, homeandTechData]);



    //********************************************************************************************* */

    // Fetch user's favorite articles during initial load
    // useEffect(() => {
    // const fetchFavorites = async () => {

    //     dispatch(showLoading()); // Dispatch showLoading action

    //     try {
    //         if (userId) {
    //             const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=article`);
    //             const favoriteIds = response.data.data;
    //             // console.log(favoriteIds);
    //             // console.log("favorite article ids are ", favoriteIds);
    //             // // setFavoriteStatus(favoriteIds);
    //             setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

    //         }
    //     } catch (error) {
    //         dispatch(hideLoading()); // Dispatch hideLoading action

    //         console.error('Error fetching favorites:', error);
    //     } finally {
    //         dispatch(hideLoading()); // Dispatch hideLoading action
    //     }
    //     };

    //     fetchFavorites();
    //     // dispatch(hideLoading()); // Dispatch hideLoading action

    // }, [userId]);







    //************************************************************************************************** */   

    const handleClick = async (articleId, collectionName) => {
        // setIsClicked(true); // Update local state for UI feedback (optional)
        try {

            const clickData = {

                articleId,
                collectionName,
                articleType: 'article',
            };
            const response = await axios.post('/api/v1/clickcount/incrementclickcount?articleType=article', clickData);

            if (!response.ok) {
                throw new Error('Error incrementing click count');
            }

            console.log('Click count incremented successfully');
        } catch (error) {
            console.error('Error incrementing click count:', error);
        }
    };





    //******************************************************************************************************************* */    


    // Handle favorite click logic with client-side state update and server-side interaction
    const handleFavoriteClick = async (articleId, collectionName) => {


        // const articleId = article._id;
        const isAlreadyFavorited = favoriteStatus.includes(articleId);

        // Optimistic UI update: Toggle favorite color based on current state
        setFavoriteStatus((prevStatus) => (isAlreadyFavorited ? prevStatus.filter((id) => id !== articleId) : [...prevStatus, articleId]));

        // Update the memoizedHomeandTechData to reflect the change
        // setMemoizedHomeandTechData((prevData) =>
        //     prevData.map((ele) =>
        //         ele._id === articleId ? { ...ele, isFavorite: !isAlreadyFavorited } : ele
        //     )
        // );



        // Update the favoriteIds in React Query cache
        queryClient.setQueryData(['favorites', userId], (oldData) =>
            isAlreadyFavorited ? oldData.filter((id) => id !== articleId) : [...oldData, articleId]
        );


        if (isAlreadyFavorited) {
            try {

                const token = localStorage.getItem('token'); // Retrieve the token from local storage

                const response = await axios.post(`/api/v1/favorite/deleteFavorite/${userId}/${articleId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                }
                );
                if (response.success) {
                    console.log('Favorite deleted successfully!');
                    setFavoriteStatus((prevStatus) => prevStatus.filter((id) => id !== articleId));
                    // setFavoriteStatus(response.articleId);
                } else {
                    console.error('Error deleting favorite:', response.message);
                }
            } catch (error) {
                console.error('Error deleting favorite:', error);
            }
        } else {
            try {
                const token = localStorage.getItem('token'); // Retrieve the token from local storage

                const favoriteData = {
                    userId,
                    articleId,
                    articleType: "article", // Or any other relevant type (modify if needed)
                    collectionName,
                };
                const response = await axios.post('/api/v1/favorite/createFavorite', favoriteData, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                }

                );
                if (response.success) {
                    console.log('Favorite created successfully!');
                    setFavoriteStatus((prevStatus) => [...prevStatus, articleId]);
                    // setFavoriteStatus(response.articleId)
                } else {
                    console.error('Error creating favorite:', response.message);
                }
            } catch (error) {
                console.error('Error creating favorite:', error);
            }
        }
    };


    //************************************************************************************************************* */



    // handlecopylink function with tostify
    // const handleCopyLink = (ele) => {
    //     try {
    //         navigator.clipboard.writeText(`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`);
    //         toast.success('Share link copied!', {
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     } catch (err) {
    //         console.error("Failed to copy link:", err);
    //         toast.error('Error copying link', {
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    // };

    //********************************************************************************** */
    //Handle copy link without toastify

    // const handleCopyLink = async (ele) => {
    //     try {
    //         await navigator.clipboard.writeText(
    //             `${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`
    //         );
    //         setIsLinkCopied(true);
    //     } catch (error) {
    //         console.error('Error copying link:', error);
    //     }
    // };


    //********************************************************************************** */
    // Log the structure of homeAndTechData



    // Add logging to check the state of homeandTechData and favoriteIds
    // console.log('homeandTechData:', homeandTechData);
    // console.log('favoriteIds:', favoriteIds);
    // console.log('combineddata', combinedData);


    // Filter and sort articles
    // const uniqueArticles = homeandTechData && homeandTechData.payload ? filterAndSortArticles(homeandTechData.payload) : [];

    // const uniqueArticles = memoizedHomeandTechData && memoizedHomeandTechData.payload ? filterAndSortArticles(memoizedHomeandTechData.payload) : [];

    // const uniqueArticles = memoizedHomeandTechData && memoizedHomeandTechData.payload ? filterAndSortArticles(memoizedHomeandTechData.payload) : [];
    const uniqueArticles = combinedData.length > 0 ? filterAndSortArticles(combinedData) : [];


    // console.log('Unique Articles are:', uniqueArticles); // Add this line

    //********************************************************************************** */





    // if (isHomeandTechLoading || isFavoritesLoading) return <div>Loading...</div>;
    // if (homeandTecherror) return <div>Error loading home and tech data</div>;
    // if (favoritesError) return <div>Error loading favorites</div>;

    if (isHomeandTechLoading || isFavoritesLoading) {
        return <div className="loading-text">Loading...</div>;

        // return (
        //     <div className="loading-text">
        //         <ClipLoader color="#00adef" loading={true} size={25} />
        //         Loading...
        //     </div>
        // );
    }
    if (homeandTecherror) {
        return <div className="loading-text">Error loading home and tech data</div>;
    }
    if (favoritesError) {
        return <div className="loading-text">Error loading favorites</div>;
    }

    return (



        <Layout>

            {/* added helmet tag for SEO  */}

            <Helmet>
                <title>Home</title>
                <meta
                    name="description"
                    content="Stay updated with the latest news in technology, gadgets, AI, and innovations. Get breaking tech news and articles from top sources."
                />
                <meta name="keywords" content="technology news, gadgets, AI, innovations, breaking news, science news, gaming, gaming news" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/dashboard" />
            </Helmet>


            {
                loading ? (
                    <Spinner />
                ) : (
                    <>
                        {/* <ToastContainer /> */}


                        <div className={isDarkMode ? 'dark-mode' : ''}>


                            <Typography sx={{ ml: 3 }}>
                                {/* <h5>Website still under construction..</h5> */}


                                <h5>Latest Technology News</h5>

                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>




                                {/* {uniqueArticles.map((ele) => ( */}

                                {uniqueArticles.length > 0 ? (
                                    uniqueArticles.map((ele) => (


                                        <>

                                            {/* <Card sx={{ maxWidth: "390px", display: "flex", m: 2 }}> */}
                                            {/* <Card key={ele._id} className="card" sx={{ width: 450, display: 'grid', gridTemplateRows: 'auto 1fr', m: 2 }}> */}
                                            <Card key={ele._id} sx={{ maxWidth: 400, display: 'grid', gridTemplateRows: 'auto 1fr', m: 2 }}>


                                                {/* <CardActionArea href={ele.link} target="_blank"> */}
                                                {/* <CardActionArea onClick={handleClick(ele._id, ele.collectionName)} href={`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleclicks`} target="_blank"> */}
                                                <CardActionArea
                                                    onClick={() => handleClick(ele._id, ele.collectionName)}
                                                    href={`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleclicks`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"  // Add this here for security
                                                >

                                                    {ele.jetpackFeaturedMediaUrl ? (
                                                        <CardMedia
                                                            sx={{ minHeight: '250px', maxHeight: '250px' }}
                                                            component={'img'}
                                                            src={ele.jetpackFeaturedMediaUrl}
                                                            alt={ele.title}
                                                            onError={(e) => {
                                                                // e.target.src = "/assets/images/Imagenotavailable.png"; // Use fallback image on error
                                                                e.target.src = "https://d2acxmwu6kqpjg.cloudfront.net/assets/images/Imagenotavailable.png"; // Use fallback image on error


                                                            }}


                                                        />
                                                    ) : (
                                                        // <ImageFiller width={400} height={200} text="Image not available" />
                                                        // <img src="/assets/images/Imagenotavailable.png" alt="Image not available" width={400} height={200} />

                                                        <img src="https://d2acxmwu6kqpjg.cloudfront.net/assets/images/Imagenotavailable.png" alt="Image not available" width={400} height={200} />


                                                    )}

                                                    <CardContent className="CardContent">
                                                        <div>

                                                            <Typography variant="h6" gutterBottom component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '4.5em', lineHeight: '1.5em' }}>                                                {ele.title}

                                                            </Typography>
                                                            <Typography variant='body' >
                                                                {ele.provider}
                                                            </Typography>

                                                            <Typography variant='body2'>
                                                                {formatTimeAgo(ele.dateGmt)} ago
                                                            </Typography>
                                                        </div>

                                                    </CardContent>



                                                </CardActionArea>
                                                <div style={{ backgroundColor: 'var(--background-color)', color: 'var(--card-text-color)' }}>
                                                    <IconButton
                                                        aria-label="like"
                                                        onClick={() => handleFavoriteClick(ele._id, ele.collectionName)}
                                                        // sx={{ bgcolor: favoriteStatus.includes(ele._id) ? 'lightcoral' : 'transparent' }}>
                                                        sx={{ ml: 1 }}>





                                                        {favoriteStatus.includes(ele._id) || ele.isFavorite ? (
                                                            <FavoriteSharp sx={{ color: 'red' }} />
                                                        ) : (
                                                            <FavoriteSharp />
                                                        )}

                                                    </IconButton>


                                                    {/* Add Share Button and Modal Logic Here */}
                                                    {/* <IconButton aria-label="share" onClick={() => setIsModalOpen(ele._id)}> */}
                                                    <IconButton aria-label="share" onClick={() => { setIsModalOpen(ele._id === isModalOpen ? false : ele._id); }}> {/* Toggle state on click */}

                                                        <Share />  {/* Optional: Icon for the share button */}
                                                    </IconButton>

                                                    {/* Modal Container (initially hidden) */}
                                                    {isModalOpen === ele._id && (
                                                        <div className="share-modal">
                                                            <h6 style={{ marginLeft: '5px', marginTop: '5px' }}>Share this article</h6>
                                                            <EmailShareButton url={ele.link} subject="Check out this article!" body="This is the article you should read.">
                                                                <Email size={32} round />
                                                            </EmailShareButton>
                                                            <FacebookShareButton url={ele.link} quote="This is a awesome article!">
                                                                <Facebook size={32} round />
                                                            </FacebookShareButton>
                                                            <WhatsappShareButton url={ele.link}>
                                                                <WhatsApp size={32} round />
                                                            </WhatsappShareButton>
                                                            <TwitterShareButton url={ele.link}>
                                                                <X size={32} round />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton url={ele.link}>
                                                                <LinkedIn size={32} round />
                                                            </LinkedinShareButton>
                                                            {/* <button onClick={() => navigator.clipboard.writeText(`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`)}>Copy Link</button> */}
                                                            {/* <button onClick={() => handleCopyLink(ele)}>Copy Link</button> */}

                                                            {/* <button onClick={() => handleCopyLink(ele)}>Copy Link</button>
                                                    {isLinkCopied && (
                                                        <div style={{ color: 'red', fontStyle: 'italic', marginLeft: '1em' }}>
                                                            *** Link copied! ***
                                                        </div>
                                                    )} */}


                                                            {/* ******** insert copy link shared component */}

                                                            <CopyLinkButton link={ele.link} />



                                                            {/* Add buttons for other platforms like Twitter, etc. */}
                                                            {/* <button onClick={() => setIsModalOpen(false)}>Close</button> */}
                                                        </div>
                                                    )}

                                                </div>



                                            </Card >


                                        </>
                                    ))


                                ) : (
                                    <div>No articles found or error loading articles, please login again or check after sometime</div>
                                )}

                            </Box>

                        </div>

                    </>
                )
            }
        </Layout >

    );
}

export default Dashboard
