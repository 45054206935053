import React, { useCallback, useState, useRef } from 'react';
import axios from 'axios';
import Spinner from '../components/shared/spinner.js';
import UploadInputForm from '../components/shared/uploadinputform.js';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import Layout from '../components/Layout/Layout';
import { FormContainer, FormColumn, ErrorSummary } from '../components/shared/styledcomponents.js';
// import TagAutocomplete from 'react-tag-autocomplete';

// import { ReactTags } from 'react-tag-autocomplete'

// import Select from 'react-select';
import TagsInput from './TagsInput.js';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';



// Function to format dateuploaded in the desired format (YYYY-MM-DD HH:MM:SS)
const getFormattedDateUploaded = () => {
    const now = new Date();
    return format(now, 'yyyy-MM-dd HH:mm:ss');
};


// Function to get dateGmt in ISO format (default toISOString())
const getFormattedDateGmt = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
};






const UploadArticle = () => {

    // const formRef = useRef(null);


    const [tags, setTags] = useState([]);

    const sections = ['Technology', 'Mobility Technology', 'MarketResearch', 'Science'];


    const [errors, setErrors] = useState({}); // State for validation errors

    const dispatch = useDispatch();


    const { loading } = useSelector((state) => state.alerts);

    const { user } = useSelector((state) => state.auth);
    const userId = user?._id; // Assuming userId is stored in '_id' property

    // const [dateGmt, setDateGmt] = useState("");

    const [date, setDate] = useState("");
    const [title, settitle] = useState("");
    const [excerpt, setexcerpt] = useState("");
    const [provider, setprovider] = useState("");
    const [jetpackFeaturedMediaUrl, setjetpackFeaturedMediaUrl] = useState("");
    const [link, setlink] = useState("");
    // const [dateuploaded, setdateuploaded] = useState("");
    // const [collectionName, setcollectionName] = useState("");

    // const [dateuploaded, setDateuploaded] = useState("");
    const [collectionName, setCollectionName] = useState("userarticles");
    const [dateError, setDateError] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [isSpammy, setIsSpammy] = useState(null);


    const [section, setSection] = useState("");


    // const dateuploaded = new Date().toISOString();

    // const dateuploaded = getFormattedDateUploaded();




    // const handleDateChange = (e) => setDate(e.target.value);
    const handleTitleChange = (e) => settitle(e.target.value);
    const handleExcerptChange = (e) => setexcerpt(e.target.value);
    const handleProviderChange = (e) => setprovider(e.target.value);


    const handleLinkChange = (e) => setlink(e.target.value);

    const handleJetpackFeaturedMediaUrlChange = (e) => setjetpackFeaturedMediaUrl(e.target.value);





    //******************************************************************************************

    const handleDateChange = (formattedDate) => {
        setDate(formattedDate);
    };

    //******************************************************************************************
    const handleTagsChange = (newTags) => {
        setTags(newTags);
    };


    //****************************************************************************************** */

    const validateInput = async () => {
        const validationErrors = {};

        if (!date) {
            validationErrors.date = 'Published date cannot be blank';
        }

        if (isFutureDate(date)) {
            validationErrors.date = 'Published date cannot be in the future.';
        }

        if (title.length < 5 || title.length > 255) {
            validationErrors.title = 'Title must be between 5 and 255 characters.';
        }

        if (excerpt.length < 10 || excerpt.length > 1000) {
            validationErrors.excerpt = 'Description must be between 10 and 1000 characters.';
        }

        if (!isValidUrl(link)) {
            validationErrors.link = 'Please enter a valid Article URL.';
        }

        if (!isValidImageUrl(jetpackFeaturedMediaUrl)) {
            validationErrors.jetpackFeaturedMediaUrl = 'Image URL must end with jpg, jpeg, png, or webp.';
        }

        if (!tags || tags.length === 0) {
            validationErrors.tags = 'Please add at least 2 tags and a maximum of 5 tags';
        }

        if (provider.length < 3 || provider.length > 100) {
            validationErrors.provider = 'Publisher must be between 3 and 1000 characters.';
        }

        return validationErrors;
    };

    //*************************************************************************************** */


    const isFutureDate = (date) => {
        const today = new Date();
        return date > today;
    };

    //*************************************************************************************** */

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (err) {
            return false;
        }
    };

    //*************************************************************************************** */


    // Optional: Implement spam URL detection logic (replace with your implementation)
    // const API_KEY = process.env.Google_Safe_Browsing_APIKEY;
    // const API_URL = `https://webrisk.googleapis.com/v1/uris:search?key=${API_KEY}`;

    //  const API_URL = '/webrisk/v1/uris:search';


    // const isSpammyUrl = async (url) => {
    //     try {
    //         const response = await axios.post(API_URL, {
    //             uri: url,
    //             threatTypes: ["MALWARE", "SOCIAL_ENGINEERING"]
    //         });

    //         return response.data.threat ? true : false;
    //     } catch (error) {
    //         console.error('Error checking URL:', error);
    //         return false; // Default to false if there's an error
    //     }
    // };


    //function using proxy to nodejs server for cors error

    // const isSpammyUrl = async (url) => {

    //     dispatch(showLoading()); // Dispatch showLoading action


    //     try {
    //         const response = await axios.get(API_URL, {
    //             params: {
    //                 uri: url,
    //                 threatTypes: "MALWARE",
    //                 // threatTypes: "SOCIAL_ENGINEERING"
    //             },
    //             timeout: 10000 // Set timeout to 10 seconds
    //         });

    //         dispatch(hideLoading()); // Dispatch showLoading action

    //         console.log('Web Risk API response:', response.data); // Log the response

    //         return response.data.threat ? true : false;

    //     } catch (error) {
    //         dispatch(hideLoading()); // Dispatch showLoading action

    //         if (error.response) {
    //             // Server responded with a status other than 2xx
    //             console.error('Error response:', error.response.data);
    //         } else if (error.request) {
    //             // Request was made but no response received
    //             console.error('Error request:', error.request);
    //         } else {
    //             // Something else happened
    //             console.error('Error message:', error.message);
    //         }
    //         return false; // Default to false if there's an error
    //     }
    // };
    //******************************************************************************************



    async function checkUrl(url) {

        if (!url) {

            toast.error("missing url");
            return;

            // throw new Error('Missing required parameters: link or apiKey');


        }
        // const response = await fetch('/api/v1/verifySpammyurl/webriskverifySpammyurl', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ url, apiKey }),
        // });


        const response = await axios.post('/api/v1/verifySpammyurl/webriskverifySpammyurl', {
            url,
        }

        );


        const data = await response.data;
        console.log(data);
        return data;
    }
    //******************************************************************************************

    const handleCheckUrl = async (url) => {

        // const data = await checkUrl(url, apiKey);
        // setIsSpammy(data.status === 'spammy');
        // return data.status === 'spammy';

        try {
            const data = await checkUrl(url);
            setIsSpammy(data.status === 'spammy');
            return data.status === 'spammy';
        } catch (error) {
            console.error('Error checking URL:', error);
            // Handle the error appropriately, e.g., display an error message to the user
        }
    };

    //******************************************************************************************

    //*************************************************************************************** */


    const isValidImageUrl = (url) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
        const extension = url.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };

    //***************************************************************************************







    //*************************************************************************************** */


    const handleSubmit = async (e) => {
        e.preventDefault();


        const validationErrors = await validateInput();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Prevent form submission if validation fails
        }



        // Basic input validation (you might want to enhance this)
        // if (!formData.title || !formData.excerpt || !formData.provider || !formData.jetpackFeaturedMediaUrl || !formData.link) {





        // if (!title || !date || !excerpt || !provider || !jetpackFeaturedMediaUrl || !link || !tags || !section) {
        //     toast.error('Please provide all fields');

        //     return;
        // }

        // Convert date to string in desired format
        // const dateGmt = "2024 -08 - 14 10: 22: 39";
        // const dateGmt = new Date(date).toISOString().split('T')[0];


        //************************************************************************************* */  

        //code commented on 13092024


        // const dateGmt = dateuploaded;

        // const dateGmt = getFormattedDateGmt();



        // const dateGmt = new Date(date).toISOString();

        // const dateuploaded = new Date(date).toISOString();

        const dateGmt = date;

        const dateuploaded = date;

        const sectionName = section;



        // Check if URL is spammy
        // const isSpammy = await isSpammyUrl(link);
        // if (isSpammy) {
        //     toast.error('URL seems to be spammy');
        //     return;
        // }

        //  console.log('Link:', link); // Debugging log
        // console.log('API Key:', apiKey); // Debugging log

        const isSpammy = await handleCheckUrl(link);
        if (isSpammy) {
            toast.error('The URL is spammy. Please provide a valid URL.');
            return;
        }



        try {
            dispatch(showLoading()); // Dispatch showLoading action


            // const response = await axios.post('api/v1/uploadarticle/uploadUserarticle', formData);

            const response = await axios.post('api/v1/uploadarticle/uploadUserarticle', {
                userId,
                dateGmt,
                title,
                excerpt,
                provider,
                jetpackFeaturedMediaUrl,
                link,
                dateuploaded,
                collectionName,
                sectionName,
                tags,
            }

            );

            console.log('Article created:', response.data);
            // Handle successful submission, e.g., show success message, redirect
            toast.success("Article uploaded successfully..");




        } catch (error) {
            dispatch(hideLoading()); // Dispatch hideLoading action




            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.error;



                if (errorMessage === 'Article already exists') {
                    console.error('Error: Article already exists');
                    toast.error('Article already exists. Please check the title and link.');
                } else if (errorMessage === 'Tags array cannot be less than 2') {
                    console.error('Error: Tags array cannot be less than 2');
                    toast.error('Tags array cannot be less than 2. Please add at least two tags.');
                } else if (errorMessage === 'Path `userId` is required.') {
                    console.error('Error: User ID is required');
                    toast.error('User ID is required to upload the article');
                } else {
                    console.error('Error creating article:', error);
                    toast.error('An error occurred while uploading the article');
                }
            } else {
                console.error('Error creating article:', error);
                toast.error('An error occurred while uploading the article');
            }
        } finally {
            dispatch(hideLoading()); // Dispatch hideLoading action
        }
    };




    // console.log("Selected tags:", selected); // Debugging line
    // console.log("Filtered suggestions for ReactTags:", filteredSuggestions); // Debugging line
    // //************************************************************************************ */
    return (

        <Layout>


            <Helmet>
                <title>Upload Articles</title>
                <meta
                    name="description"
                    content="Upload news articles."
                />
                <meta name="keywords" content="Upload news articles" />
                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href="https://app.heshmore.com/uploadarticle" />
            </Helmet>


            {
                loading ? (
                    <Spinner />
                ) : (

                    <div className='form-container_uploadarticle'>


                        <div className='imagelogo'>
                            {/* <img src='/assets/images/HeshMoreLogo.png'
                                alt='HeshMoreApp Logo'
                                height={50}
                                width={50}
                                margin-top={20}>
                            </img> */}

                            <img src='https://d2acxmwu6kqpjg.cloudfront.net/assets/images/HeshMoreLogo.png'
                                alt='HeshMoreApp Logo'
                                height={50}
                                width={50}
                                margin-top={20}>
                            </img>
                        </div>
                        {/* <h5 className="text-center text-primary">HeshMore App</h5> */}
                        {/* <h3>Upload Feature is still in development mode, uploaded articles will not be shown on the website</h3> */}
                        <h3 className="center-heading">Upload Articles</h3>


                        <form onSubmit={handleSubmit} style={{ textAlign: 'left', marginBottom: '20px', marginLeft: '50px' }}>
                            {/* <div className='imagelogo'>
                                <img src='/assets/images/HeshMoreLogo.png'
                                    alt='HeshMoreApp Logo'
                                    height={50}
                                    width={50}
                                    margin-top={20}>
                                </img>
                            </div>
                            <h5 className="text-center text-primary">HeshMore App</h5> */}

                            <FormContainer>
                                <FormColumn>



                                    <UploadInputForm
                                        htmlFor="date"
                                        labeltext={'Article Published Date:'}
                                        type="date"
                                        value={date} // Pass the current date state value
                                        handlechange={handleDateChange} // Pass the handleDateChange function for updating state in the parent
                                        fieldName="date"
                                    />

                                    {/* {dateError && <span className="error-message">{dateError}</span>} */}



                                    <UploadInputForm
                                        htmlFor="name"
                                        labeltext={'Title:'}
                                        type={'text'}
                                        value={title}
                                        handlechange={handleTitleChange}
                                        fieldName="title" // New prop value for the field name

                                    />

                                    <UploadInputForm
                                        htmlFor="name"
                                        labeltext={'Description:'}
                                        type={'text'}
                                        value={excerpt}
                                        handlechange={handleExcerptChange}
                                        fieldName="excerpt" // New prop value for the field name

                                    />
                                    <label>Section:</label>
                                    <select value={section} onChange={(e) => setSection(e.target.value)}>
                                        <option value="">Select Section</option>
                                        {sections.map(section => (
                                            <option key={section} value={section}>
                                                {section}
                                            </option>
                                        ))}
                                    </select>

                                </FormColumn>
                                <FormColumn>

                                    <UploadInputForm
                                        htmlFor="name"
                                        labeltext={'Publisher:'}
                                        type={'text'}
                                        value={provider}
                                        handlechange={handleProviderChange}
                                        fieldName="provider" // New prop value for the field name
                                    />

                                    <UploadInputForm
                                        htmlFor="name"
                                        labeltext={'Featured Image URL:'}
                                        type={'text'}
                                        value={jetpackFeaturedMediaUrl}
                                        handlechange={handleJetpackFeaturedMediaUrlChange}
                                        fieldName="jetpackFeaturedMediaUrl" // New prop value for the field name
                                    />

                                    <UploadInputForm
                                        htmlFor="name"
                                        labeltext={'Article Url:'}
                                        type={'text'}
                                        value={link}
                                        handlechange={handleLinkChange}
                                        fieldName="link" // New prop value for the field name

                                    />




                                    <TagsInput onTagsChange={handleTagsChange} />
                                    {/* {tagsError && <span className="error-message">{tagsError}</span>} */}


                                </FormColumn>

                                {Object.keys(errors).length > 0 && (
                                    <div className="error-summary">
                                        <h3>Please correct the following errors:</h3>
                                        <ul>
                                            {Object.entries(errors).map(([field, message]) => (
                                                <li key={field}>{message}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}


                            </FormContainer>


                            <button type="submit" className="btn btn-primary center-button" style={{ textAlign: 'center' }}>Upload</button>


                        </form>


                    </div>


                )
            }

        </Layout>

    )

};

export default UploadArticle;

