import React, { useState, useEffect } from 'react';
import CookiePreferencesModal from './CookiePreferenceModel';

const CookieConsent = () => {
    const [showConsent, setShowConsent] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);


    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShowConsent(true);
        } else {
            setConsentGiven(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowConsent(false);
        setConsentGiven(true);
    };

    const handleManagePreferences = () => {
        // Open a modal or display more options for consent management
        setShowPreferencesModal(true); // Open the preferences modal

        console.log('Opening preferences modal...');
    };


    const handleClosePreferences = () => {
        setShowPreferencesModal(false);
    };

    const handleSavePreferences = (preferences) => {
        // Handle the saved preferences (e.g., send to server, apply settings, etc.)
        console.log('User preferences saved:', preferences);
    };





    return (
        <>
            {!consentGiven && (
                <div style={{ position: 'fixed', bottom: '10px', left: '10px', backgroundColor: '#00adef', padding: '10px', border: '1px solid #ccc', zIndex: 1000 }}>
                    <p>


                        We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies. Please see our <a href="/privacypolicy">Privacy Policy</a> for more information.

                    </p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <button className="btn btn-primary" onClick={handleAccept}>Accept</button>
                        <button className="btn btn-primary" onClick={handleManagePreferences}>Manage Preferences</button>
                    </div>
                </div>
            )}
            {showPreferencesModal && (
                <CookiePreferencesModal
                    onClose={handleClosePreferences}
                    onSave={handleSavePreferences}
                />
            )}
        </>
    );
};

export default CookieConsent;
