import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you're using axios for API calls
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';



const VerifyEmail = () => {
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [error, setError] = useState(null);
    const [resendDisabled, setResendDisabled] = useState(false);


    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');
        if (token) {
            axios.get(`/api/v1/auth/verify?token=${token}`)
                .then(response => {
                    setVerificationStatus(response.data);
                })
                .catch(error => {
                    // Check for specific error message indicating expired link
                    if (error.response && error.response.data.message === 'Verification link expired. Please resend verification email.') {
                        setError(error.response.data.message); // Set the specific error message
                    } else {
                        // Handle other errors
                        setError(error.response.data.message); // Set the specific error message

                        console.error(error); // Log other errors for debugging
                    }
                });
        }
    }, []);

    //********************************************************************************************* */

    const handleResend = async () => {
        // Implement logic to resend verification email

        const token = new URLSearchParams(window.location.search).get('token');


        try {

            const reponse = await axios.post('/api/v1/auth/reverificationemail/', { token });
            toast.success('Verification Email send successfully');
            setResendDisabled(true); // Disable the button

            // Re-enable the button after 5 minutes
            // setTimeout(() => {
            //     setResendDisabled(false);
            // }, 5 * 60 * 1000);

        } catch (error) {

            toast.error('Unable to send verification link due to error ' + error.message)

            console.error(error);
        }


    };

    return (
        <div className="verify-email-container">
            <div className="content-container">
                {/* Logo image */}
                {/* <img src='/assets/images/HeshMoreLogo.png' alt='HeshMoreApp Logo' height={50} width={50}></img> */}

                <img src='https://d2acxmwu6kqpjg.cloudfront.net/assets/images/HeshMoreLogo.png' alt='HeshMoreApp Logo' height={50} width={50}></img>

                <h2>HeshMoreApp</h2>
                <br></br>

                {verificationStatus && verificationStatus.success ? (
                    <h4>Email verified successfully! Please <Link to="/login">login</Link></h4>

                ) : error ? (
                    <div style={{ color: 'red' }}>
                        <p>Error: {error}</p>
                        <button onClick={handleResend} disabled={resendDisabled}>
                            {resendDisabled ? 'Resend disabled' : 'Resend Verification Email'}
                        </button>
                    </div>
                ) : (
                    <p>Verifying email...</p>
                )}
            </div>
        </div>
    );
};

export default VerifyEmail;
