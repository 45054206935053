import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Inputform from '../components/shared/inputform';
import e from 'cors';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import axios from 'axios';
import Spinner from '../components/shared/spinner.js';
import { toast } from 'react-toastify';
import Layout from '../components/Layout/Layout.js';
import { Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import '../styles/ProfileStyle.css';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material/styles';
import ImageCropper from '../components/Layout/ImageCropper.js';
import { Helmet } from 'react-helmet';




// Styled hidden file input
const Input = styled('input')({
    display: 'none',
});



const UpdateProfile = () => {
    const { user } = useSelector((state) => state.auth); // Access user data from Redux
    const [name, setName] = useState(user?.name || ''); // Use initial value from user data
    const [email, setEmail] = useState(user?.email || ''); // Use initial value from user data
    // const [loading, setLoading] = useState(false); // No initial loading needed
    const [password, setpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const googlecredential = user?.googlecredential; // Access googlecredential field
    const exsitingprofileImageUrl = user?.profileImageUrl;
    // const exsitingprofileImageUrl = ''

    const cloudFrontDomain = 'https://d1tb4510j7m0jn.cloudfront.net'; // Your CloudFront domain

    const cloudFrontUrl = exsitingprofileImageUrl ? exsitingprofileImageUrl.replace('https://heshmoreappimages.s3.amazonaws.com', cloudFrontDomain) : null;


    const [profileImageUrl, setprofileImageUrl] = useState('');

    const [file, setFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isCropping, setIsCropping] = useState(false);






    //redux state

    const { loading } = useSelector((state) => state.alerts);


    //hooks

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //********************************************************************************************** */

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5 MB size limit

        if (selectedFile.size > maxSize) {
            alert('File size exceeds the 5 MB limit. Please choose a smaller file.');
            return;
        }

        setFile(URL.createObjectURL(selectedFile));
        setIsCropping(true);
    };



    const handleCropComplete = async (croppedImage) => {
        setCroppedImage(URL.createObjectURL(croppedImage));
        setIsCropping(false);

        const formData = new FormData();
        formData.append('file', croppedImage);

        try {
            const response = await fetch('/api/v1/user/uploadProfileImage/', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Upload failed');
            }

            const data = await response.json();
            setprofileImageUrl(data.fileUrl); // Update profile image URL
        } catch (error) {
            console.error('Error uploading file:', error);
            // Handle error (e.g., show a toast message)
        }
    };
    //**********************************************************************************************


    const handleAvatarClick = () => {
        document.getElementById('profile-image-input').click(); // Trigger file input click
    };

    //**********************************************************************************************
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission logic (call your update user endpoint)

        try {
            // if (!name || !email || !password || !confirmpassword) {
            //     toast.error('Please provide all fields');
            //     return;
            // }

            // if (password !== confirmpassword) {
            //     toast.error('Passwords do not match');
            //     return; // Exit early if passwords don't match
            // }

            if (!name) {

                toast.error('Please provide all fields to update');
                return;
            }

            dispatch(showLoading());

            const token = localStorage.getItem('token'); // Replace with your token key
            const headers = {};
            if (token) {
                headers['authorization'] = `Bearer ${token}`;
            }

            const { data } = await axios.put('api/v1/user/updateuser', {
                name,
                email,
                // password
                profileImageUrl,
            }, { headers });

            if (data.success) {
                dispatch(hideLoading());
                toast.success('User Profile Updated Successfully, please login');
                localStorage.clear(); // Clear all local storage
                navigate('/login');
            }

        } catch (error) {
            dispatch(hideLoading());
            toast.error("Something went wrong please try again");
            console.log(error);
        }
    };

    return (
        <Layout>

            <Helmet>
                <title>Update Profile</title>
                <meta
                    name="description"
                    content="Update Profile page for app.heshmore.com"
                />
                <meta name="keywords" content="heshmore app, update profile page" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/updateprofile" />
            </Helmet>



            {loading ? (
                <Spinner />
            ) : (
                <>


                    <div className="form-container">
                        <Typography variant="h4" component="h2">
                            Update Profile
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            {/* <div className="imagelogo">
                                <img src="/assets/images/HeshMoreLogo.png" alt="HeshMoreApp Logo" height={50} width={50} />
                            </div>
                            <h5 className="text-center text-primary">HeshMore App</h5> */}
                            <br></br>

                            <Typography variant="body1" component="span" sx={{ margin: '5px 0', fontWeight: 'bold' }}>
                                Profile Picture:
                            </Typography>

                            {/* <div className="profile-image-container">
                            {profileImageUrl ? (
                                <img
                                    src={profileImageUrl}
                                    alt="Profile"
                                    className="circular-image"
                                />
                            ) : (
                                // <p>No profile image available</p>
                                <AccountCircleOutlinedIcon
                                    style={{ fontSize: 25 }} // Adjust size if needed
                                    className="circular-image"
                                />
                            )}
                        </div> */}
                            {/* <div className="profile-container">
                                <Avatar
                                    src={profileImageUrl}
                                    alt="Profile"
                                    style={{ width: 50, height: 50 }}
                                >
                                    {!profileImageUrl && <PersonIcon />}
                                </Avatar>
                            </div>
                            <br></br> */}

                            <div className="profile-container">
                                <Avatar
                                    src={cloudFrontUrl}
                                    alt="Profile"
                                    style={{ width: 50, height: 50, cursor: 'pointer' }}
                                    onClick={handleAvatarClick}
                                >
                                    {!exsitingprofileImageUrl && <PersonIcon />}
                                </Avatar>
                                <Input
                                    id="profile-image-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                <div className="upload-container">
                                    {croppedImage && !isCropping && (
                                        <img src={croppedImage} alt="Cropped" className="circular-image" />
                                    )}
                                    {isCropping && (
                                        <ImageCropper imageSrc={file} onCropComplete={handleCropComplete} />
                                    )}
                                </div>
                            </div>



                            <Typography variant="body2" component="span" sx={{ display: 'block', margin: '5px 0', fontStyle: 'italic' }}>
                                *Click on the profile picture to update the image
                            </Typography>



                            <Inputform
                                htmlFor="name"
                                labeltext={'Name:'}
                                type={'text'}
                                value={name}
                                handlechange={(e) => setName(e.target.value)}
                                name="name"
                                labelStyle={{ className: 'bold-label' }} // Apply class here


                            />

                            <Typography variant="body1" component="span" sx={{ margin: '5px 5px', fontWeight: 'bold', marginBottom: '10px' }}>
                                Email: {email}
                            </Typography>
                            <br></br>

                            {/* <Inputform
                            htmlFor="email"
                            labeltext={'Email:'}
                            type={'email'}
                            value={email}
                            handlechange={(e) => setEmail(e.target.value)}
                            name="email"
                            disabled={true} // Add this attribute to disable the field


                        /> */}

                            {/* <Inputform
                            htmlFor="password"
                            labeltext={'Password:'}
                            type={'password'}
                            value={password}
                            handlechange={(e) => setpassword(e.target.value)}
                            name="password"
                        />

                        <Inputform
                            htmlFor="confirmpassword"
                            labeltext={'Confirm Password:'}
                            type={'password'}
                            value={confirmpassword}
                            handlechange={(e) => setconfirmpassword(e.target.value)}
                            name="confirmpassword"
                        /> */}

                            <br></br>

                            <button type="submit" className="btn btn-primary" sx={{ margin: '10px 10px' }}>
                                Update Profile
                            </button>



                            {/* <div className="d-flex justify-content-between">
                            <p>Already Registered? <Link to="/login">Login</Link></p>
                        </div> */}
                        </form>

                    </div>
                </>
            )}
        </Layout>
    );
};

export default UpdateProfile;
