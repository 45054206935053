import React from "react";
import '../styles/Homepage.css'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



const HomePage = () => {

    return (
        <>

            <Helmet>
                <title>Heshmore App</title>
                <meta
                    name="description"
                    content="Stay updated with the latest news in technology, gadgets, AI, and innovations. Get breaking tech news and articles from top sources."
                />
                <meta name="keywords" content="heshmore app home page" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/" />
            </Helmet>




            {/* 
            <video autoPlay muted loop id="myvideo" className="myvideo">

                <source src="/assets/videos/heshmoreapp_backgroundvideo.mp4" type="video/mp4" />
            </video> */}

            {/* <img src="assets/images/HeshMoreAppBackgroundImage.jpg" alt="heshmoreapp background" className="heshmoreappbackgroundimage" /> */}


            {/* serving the image from cloudfront  */}

            <img src="https://d2acxmwu6kqpjg.cloudfront.net/assets/images/HeshMoreAppBackgroundImage.jpg" alt="heshmoreapp background" className="heshmoreappbackgroundimage" />


            <div className="content">

                {/* <div className="card w-25 text-center d-flex justify-content-center"> */}
                {/* <div className="card text-center d-flex justify-content-center">


                    <img src="/assets/images/HeshMoreLogo.png" alt="heshmoreapp logo" className="mx-auto" style={{ width: '50px', height: '50px', marginTop: '10px' }} />
                    <div className="card-body">
                        <h5 className="card-title">HeshMore App</h5>
                        <p className="card-text">Technology, Science, Mobility, Market Research News</p>
                        <div>
                            <p>Not a user Register <Link to="/register">Here !</Link></p>
                            <p>
                                <Link to="/login" className="mybtn">Login</Link>
                            </p>
                        </div>
                    </div>

                </div> */
                    <div className="card text-center d-flex justify-content-center">
                        {/* <img src="/assets/images/HeshMoreLogo.png" alt="heshmoreapp logo" className="mx-auto" style={{ width: '50px', height: '50px', marginTop: '10px' }} /> */}


                        {/* serving the image from cloudfront */}

                        <img src="https://d2acxmwu6kqpjg.cloudfront.net/assets/images/HeshMoreLogo.png" alt="heshmoreapp logo" className="mx-auto" style={{ width: '50px', height: '50px', marginTop: '10px' }} />


                        <div className="card-body">
                            <h5 className="card-title">HeshMore App</h5>
                            <p className="card-text">Technology, Science, Mobility, Market Research News</p>
                            <p>Not a user Register <Link to="/register">Here !</Link></p>
                            <p>
                                <Link to="/login" className="mybtn">Login</Link>
                            </p>
                        </div>
                    </div>



                }


            </div>



        </>

    )

}

export default HomePage;