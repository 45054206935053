import React, { useState } from 'react';
import DatePicker from 'react-datepicker'; // Import your preferred date picker library
import 'react-datepicker/dist/react-datepicker.css';


const UploadInputForm = ({ htmlFor, labeltext, type, name, value, handlechange, fieldName, hasError }) => {
    const inputStyle = hasError ? { border: '1px solid red' } : {};


    const [selectedDate, setSelectedDate] = useState(null); // Initialize with null

    const handleDateChange = (selectedDate) => {

        // Add the current time to the selected date
        const now = new Date();
        // const dateWithTime = new Date(date);



        // Extract the current time components from the system time
        const currentHours = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentSeconds = now.getSeconds();

        // dateWithTime.setHours(now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        // const dateWithTime = new Date(Date.UTC(
        //     date.getUTCFullYear(),
        //     date.getUTCMonth(),
        //     date.getUTCDate(),
        //     now.getUTCHours(),
        //     now.getUTCMinutes(),
        //     now.getUTCSeconds()
        // ));


        // Create a new Date object using the selected date and current time
        const combinedDateTime = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            currentHours, // Use current system hour
            currentMinutes, // Use current system minutes
            currentSeconds // Use current system seconds
        );


        // Convert to UTC
        const utcDate = new Date(Date.UTC(
            combinedDateTime.getFullYear(),
            combinedDateTime.getMonth(),
            combinedDateTime.getDate(),
            combinedDateTime.getHours(),
            combinedDateTime.getMinutes(),
            combinedDateTime.getSeconds()
        ));

        // Convert to ISO string
        const formattedDate = combinedDateTime.toISOString();

        // Convert the date with time to an ISO string in UTC
        // const formattedDate = dateWithTime.toISOString();


        // Update the state and pass the formatted date to the parent
        setSelectedDate(utcDate);
        handlechange(formattedDate); // Pass the ISO 8601 string to the parent component

        // setSelectedDate(date);
        // handlechange(date); // Call parent's handlechange with the date object
        // Format the date to ISO 8601 string in UTC before sending to the parent component
        // const formattedDate = date ? date.toISOString() : null;
        // handlechange(formattedDate); // Call parent's handlechange with the formatted date string

    };
    return (
        <>
            {/* <div className="mb-3"> */}

            <div className="form-group">

                <label htmlFor={htmlFor} className="form-label">
                    {labeltext}
                </label>
                {type === 'date' ? (
                    // <DatePicker
                    //     selected={value}
                    //     // onChange={(date) => handlechange(date)} // Pass the date object
                    //     // onChange={handleDateChange} // Use the custom handleDateChange
                    //     onChange={handlechange} // Use the custom handleDateChange


                    //     name={fieldName} // Use fieldName for the name attribute
                    //     className="form-control" // Add optional class for styling
                    //     style={inputStyle}

                    // />

                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange} // Use the custom handleDateChange
                        name={fieldName}
                        className="form-control"
                        style={inputStyle}
                    />
                ) : (
                    <input
                        type={type}
                        className="form-control"
                        name={fieldName} // Use fieldName for the name attribute
                        value={value}
                        onChange={handlechange}
                        style={inputStyle}

                    />
                )}
            </div>
        </>
    );
};

export default UploadInputForm;
