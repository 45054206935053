import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import NotFound from './pages/NotFound.js';
import Dashboard from './pages/Dashboard.js'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/routes/PrivateRoute.js';
import PublicRoute from './components/routes/PublicRoute.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import Menu from './pages/Menu.js';
import TermsandConditions from './pages/TermsandConditions.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import Technology from './pages/Technology.js';
import Science from './pages/Science.js';
import Videos from './pages/Videos.js';
import MobilityTechnology from './pages/MobilityTechnology.js';
import MarketResearch from './pages/MarketResearch.js';
import SearchPage from './pages/SearchPage.js';
import FavoriteArticles from './pages/FavoriteArticles.js';
import FavoriteVideos from './pages/FavoriteVideos.js';
import UpdateProfile from './pages/UpdateProfile.js';
import Profile from './pages/Profile.js';
import TrendingArticles from './pages/TrendingArticles.js';
import TrendingVideos from './pages/TrendingVideos.js'
import VerifyEmail from './pages/VerifyEmail.js';
import ResetPassword from './pages/ResetPassword.js';
import UploadArticle from './pages/UploadArticle.js';
import UserUploadedArticles from './pages/UserUploadedArticles.js';


import CreatorRoute from './components/routes/CreatorRoute.js';

import CookieConsent from './components/shared/CookieConsent.js';

// const clientId = process.env.GOOGLE_CLIENT_ID; // Replace with your Google client ID

function App() {
  return (

    <>

      <ToastContainer />

      <CookieConsent /> {/* Place CookieConsent here to show on all pages */}

      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <HomePage />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute >
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute >
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/about"
          element={
            <PrivateRoute>
              <About />
            </PrivateRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />
        <Route
          path="/menu"
          element={
            <PrivateRoute>
              <Menu />
            </PrivateRoute>
          }
        />
        <Route
          path="/termsandconditions"
          element={
            <PrivateRoute>
              <TermsandConditions />
            </PrivateRoute>
          }
        />

        <Route
          path="/privacypolicy"
          element={
            <PublicRoute>
              <PrivacyPolicy />
            </PublicRoute>
          }
        />

        <Route
          path="/technology"
          element={
            <PrivateRoute>
              <Technology />
            </PrivateRoute>
          }
        />

        <Route
          path="/science"
          element={
            <PrivateRoute>
              <Science />
            </PrivateRoute>
          }
        />

        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <Videos />
            </PrivateRoute>
          }
        />

        <Route
          path="/mobilitytechnology"
          element={
            <PrivateRoute>
              <MobilityTechnology />
            </PrivateRoute>
          }
        />

        <Route
          path="/marketresearch"
          element={
            <PrivateRoute>
              <MarketResearch />
            </PrivateRoute>
          }
        />
        <Route
          path="/searchpage"
          element={
            <PrivateRoute>
              <SearchPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/favoritearticles"
          element={
            <PrivateRoute>
              < FavoriteArticles />
            </PrivateRoute>
          }
        />

        <Route
          path="/favoritevideos"
          element={
            <PrivateRoute>
              < FavoriteVideos />
            </PrivateRoute>
          }
        />

        <Route
          path="/trendingarticles"
          element={
            <PrivateRoute>
              < TrendingArticles />
            </PrivateRoute>
          }
        />

        <Route
          path="/trendingvideos"
          element={
            <PrivateRoute>
              < TrendingVideos />
            </PrivateRoute>
          }
        />
        <Route
          path="/updateprofile"
          element={
            <PrivateRoute>
              < UpdateProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              < Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/uploadarticle"
          element={
            <CreatorRoute>
              < UploadArticle />
            </CreatorRoute>
          }
        />

        <Route
          path="/userarticles"
          element={
            <CreatorRoute>
              < UserUploadedArticles />
            </CreatorRoute>
          }
        />


        <Route path="*" element={<NotFound />} />

        <Route path="/verify" element={<VerifyEmail />} />

        <Route path="/resetpassword" element={<ResetPassword />} />



      </Routes>



    </>
  );
}
export default App;
