
import React, { useState, useRef } from 'react';
import useTags from './useTags';

const predefinedTags = [
    'technology',
    'science',
    'mobilitytechnology',
    'health',
    'education',
    'finance',
    'sports',
    'entertainment',
    'medicaltechnology',
    'technews',
    'sciencenews',
    'autotechnology',
    'computers',
    'gadgets',
    'wearables',
    'semiconductors',
    'marketresearch',
    'chips',
    'nasa',
    'space',
    'aerospace',
    'astronomy',
    'biomedical',
    'eVtol',
    'electricvehicles',
    'business',
    'defence',
    'sportstech',
    'artificialintelligence',
    'virtualreality',
    'metaverse',
    'smartwatches',
    'bitcoin',
    'cryptocurrency',
    'bigtech',
    'aviation',
    'telecom',
    '5g',
    'weather',
    'earth',
    'carbon',
    'pollution',
    'environment',
    'gaming',
    'fintech',
    'howto',
    'manufacturing',
    'marinetechnology',
    'transport',
    'urbanmobility',
    'shipping',
    'Facebook',
    'Google',
    'Nivida',
    'Apple',
    'IBM',
    'Netflix',
    'Amazon',
    'Aws',
    'Microsoft',
    'Arm',
    'Samsung',
    'twitter',
    'x',
    'Elon Musk',
    'Mark Zuckerberg',
    'quantum',
    'quantum computing',
    'CES',
    'NYSE',
    'genAI',
    'FIFA',
    'IOT',
    'atomic',
    'CERN',
    'physics',
    'planet',
    'X-RAY',
    'qualcomm',
    'snapdragon',
    'cockpit',
    'epic games',
    'unreal engine',
    'invehicletech'

];

const TagsInput = ({ onTagsChange }) => {
    const { tags, addTag, removeTag, getTags } = useTags([], 5);
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const query = e.target.value;
        setInputValue(query);
        if (query) {
            const filtered = predefinedTags.filter((tag) =>
                tag.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSuggestions(filtered);
        } else {
            setFilteredSuggestions([]);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            addTag(inputValue.trim());
            setInputValue('');
            setFilteredSuggestions([]);
            setError('');
            onTagsChange(getTags());
        }
    };

    const handleRemoveTag = (index) => {
        removeTag(index);
        onTagsChange(getTags());
    };

    const handleSuggestionClick = (suggestion) => {
        addTag(suggestion);
        setInputValue('');
        setFilteredSuggestions([]);
        setError('');
        onTagsChange(getTags());
    };

    const handleBlur = () => {
        if (tags.length === 0) {
            setError('Please add at least one tag.');
        } else {
            setError('');
        }
    };

    return (
        <div className="tags-input-wrapper">
            <label htmlFor="tags-input">Add Tags</label>
            <div className="tags-input-container">
                {tags.map((tag, index) => (
                    <div key={index} className="tag-item">
                        <span className="text">{tag}</span>
                        <span className="close" onClick={() => handleRemoveTag(index)}>×</span>
                    </div>
                ))}
                {tags.length < 5 && (
                    <input
                        id="tags-input"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        placeholder="Add a tag"
                    />
                )}
                {filteredSuggestions.length > 0 && (
                    <div className="suggestions-container">
                        {filteredSuggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className="suggestion-item"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {error && <p className="error">{error}</p>}
            <p className="note">Add up to a maximum of 5 tags</p>
        </div>
    );
};

export default TagsInput;





// const TagsInput = ({ onTagsChange }) => {
//     const { tags, addTag, removeTag, getTags } = useTags([], 5);
//     const [inputValue, setInputValue] = useState('');
//     const [filteredSuggestions, setFilteredSuggestions] = useState([]);
//     const [error, setError] = useState('');
//     const selectedSuggestionIndex = useRef(-1); // Tracks currently selected suggestion

//     const handleInputChange = (e) => {
//         const query = e.target.value;
//         setInputValue(query);
//         if (query) {
//             const filtered = predefinedTags.filter((tag) =>
//                 tag.toLowerCase().includes(query.toLowerCase())
//             );
//             setFilteredSuggestions(filtered);
//         } else {
//             setFilteredSuggestions([]);
//             selectedSuggestionIndex.current = -1; // Reset selection on empty input
//         }
//     };

//     const handleKeyDown = (e) => {
//         if (e.key === 'Enter' && inputValue.trim()) {
//             addTag(inputValue.trim());
//             setInputValue('');
//             setFilteredSuggestions([]);
//             setError('');
//             onTagsChange(getTags());
//             selectedSuggestionIndex.current = -1; // Reset selection on tag addition
//         } else if
//             // (e.key === 'ArrowDown' && filteredSuggestions.length > 0) {
//             //     // Handle down arrow key for suggestion selection
//             //     selectedSuggestionIndex.current = Math.min(
//             //         selectedSuggestionIndex.current + 1,
//             //         filteredSuggestions.length - 1
//             //     );

//             (e.key === 'ArrowDown') {
//             selectedSuggestionIndex.current = Math.min(
//                 selectedSuggestionIndex.current + 1,
//                 filteredSuggestions.length - 1
//             );

//             // Ensure focus is on the selected suggestion
//             const selectedSuggestionElement = document.querySelector('.suggestion-item.selected');
//             if (selectedSuggestionElement) {
//                 selectedSuggestionElement.focus();
//             }

//         } else if (e.key === 'ArrowUp' && filteredSuggestions.length > 0) {
//             // Handle up arrow key for suggestion selection
//             selectedSuggestionIndex.current = Math.max(
//                 selectedSuggestionIndex.current - 1,
//                 0
//             );
//         }
//     };

//     const handleRemoveTag = (index) => {
//         removeTag(index);
//         onTagsChange(getTags());
//     };

//     const handleSuggestionClick = (suggestion) => {
//         addTag(suggestion);
//         setInputValue('');
//         setFilteredSuggestions([]);
//         setError('');
//         onTagsChange(getTags());
//     };

//     const handleBlur = () => {
//         if (tags.length === 0) {
//             setError('Please add at least one tag.');
//         } else {
//             setError('');
//         }
//     };

//     return (
//         <div className="tags-input-wrapper">
//             <label htmlFor="tags-input">Add Tags</label>
//             <div className="tags-input-container">
//                 {tags.map((tag, index) => (
//                     <div key={index} className="tag-item">
//                         <span className="text">{tag}</span>
//                         <span className="close" onClick={() => handleRemoveTag(index)}>
//                             ×</span>
//                     </div>
//                 ))}
//                 {tags.length < 5 && (
//                     <input
//                         id="tags-input"
//                         type="text"
//                         value={inputValue}
//                         onChange={handleInputChange}
//                         onKeyDown={handleKeyDown}
//                         onBlur={handleBlur}
//                         placeholder="Add a tag"
//                     />
//                 )}
//                 {filteredSuggestions.length > 0 && (
//                     <div className="suggestions-container">
//                         {filteredSuggestions.map((suggestion, index) => (
//                             <div
//                                 key={index}
//                                 className={`suggestion-item ${selectedSuggestionIndex.current === index ? 'selected' : ''
//                                     }`}
//                                 onClick={() => handleSuggestionClick(suggestion)}
//                             >
//                                 {suggestion}
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//             {error && <p className="error">{error}</p>}
//             <p className="note">Add up to a maximum of 5 tags</p>
//         </div>
//     );
// };

// export default TagsInput;